<template>
  <div class="specialProject">
    <div class="specialListWarp">
      <div class="specialList" v-for="(item, index) in typeList" :key="index" @click="goNext(item)">
        <div class="num">{{index + 1}}</div>
        <div class="contentText">
          <p class="chnalanguage">{{item.typeLan}}</p>
          <p class="otherlanguage">{{item.type}}</p>
        </div>
        <div class="allNum">{{item.num}}题</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getspeacilList, getcoumsList } from '@/api/index'
export default {
  data() {
    return {
      car: '',
      loading: false,
      typeList: []
    }
  },
  created() {
    this.car = this.$route.query.id
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      getspeacilList(this.car).then(res => {
        this.typeList = res.data
      })
    },
    goNext(e) {
      if (e.num == 0) return
      getcoumsList({ chooseTitle: e.type, car: this.car }).then(res => {
        localStorage.setItem('tixing', JSON.stringify(res.data))
          this.$router.push({
            path: '/exercise',
            query: {
              id: this.car
            }
          })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.specialProject{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: linear-gradient( 90deg, #F4F8FB 0%, #FAF6F5 100%);
  border-radius: 0px 0px 0px 0px;
  .specialListWarp{
    padding: 16px 0;
    .specialList{
      width: 375px;
      height: 50px;
      margin-bottom: 4px;
      background: #FFFFFF;
      border-radius: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .num{
        width: 27px;
        height: 27px;
        border-radius: 50%;
        font-size: 16px;
        line-height: 27px;
        margin: 10px 10px 10px 16px;
        background: #6493FF;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
      .contentText{
        width: 267px;
        .chnalanguage{
          width: 267px;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 超出部分隐藏 */
          text-overflow: ellipsis; /* 显示省略号 */
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 17px;
          color: #131313;
          font-style: normal;
          text-transform: none;
        }
        .otherlanguage{
          width: 267px;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 超出部分隐藏 */
          text-overflow: ellipsis; /* 显示省略号 */
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 13px;
          color: #131313;
          font-style: normal;
          text-transform: none;
        }
      }
      .allNum{
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 13px;
        color: #131313;
        font-style: normal;
        text-transform: none;
      }
    }
  }
  
}
</style>
